<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        When ammonia and hydrochloric acid vapours are mixed, solid ammonium chloride salt is
        produced.
      </p>

      <p class="mb-4 pl-8">
        <chemical-latex content="NH3(g) + HCl(g) -> NH4Cl(s)" />
      </p>

      <p class="mb-3">
        Suppose a
        <latex-number number="2.50" unit="\text{L}" />
        flask containing
        <number-value :value="HClPinit" unit="\text{kPa}" />
        hydrochloric acid gas at
        <number-value :value="roomTemp" unit="^\circ\text{C}" />
        was injected with
        <latex-number number="50.0" unit="\text{mL}" />
        of ammonia vapour at
        <number-value :value="NH3Pinit" unit="\text{kPa}" />
        at
        <number-value :value="NH3Tinit" unit="^\circ\text{C,}" />
        and the reaction is allowed to proceed to completion and return to
        <number-value :value="roomTemp" unit="^\circ\text{C.}" />
        Determine the final pressure of each gas <b>after</b> the reaction has occurred.
      </p>

      <calculation-input
        v-model="inputs.PHCl"
        class="mb-3"
        prepend-text="$\text{P}_\text{HCl}:$"
        append-text="$\text{kPa}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.PNH3"
        class="mb-0"
        prepend-text="$\text{P}_\text{NH3}:$"
        append-text="$\text{kPa}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question452',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        PHCl: null,
        PNH3: null,
      },
    };
  },
  computed: {
    HClPinit() {
      return this.taskState.getValueBySymbol('HClPinit').content;
    },
    NH3Pinit() {
      return this.taskState.getValueBySymbol('NH3Pinit').content;
    },
    NH3Tinit() {
      return this.taskState.getValueBySymbol('NH3Tinit').content;
    },
    roomTemp() {
      return this.taskState.getValueBySymbol('roomTemp').content;
    },
  },
};
</script>
